<template>
    <div class="d-flex quizell-type justify-content-center align-items-center">
        <input :type="inputType" class="w-100 quizell-form-control px-2"  v-bind="$attrs"  v-model="text"
    @input="$emit('input',text)">
        
    </div>

</template>
<script>
export default{
    data(){
        return{
            visible:false,
            text:''
        }
    },
    props:{
        inputType:{
            default:'text',
            type:String
        }
    }
}

</script>
<style scoped>
.quizell-type:focus-within {
    color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.quizell-form-control{
border: none;
    height: 48px;
    border-radius: 10px;
}
.quizell-form-control:focus-visible{
    border: none!important;;
    outline: none;
}
.quizell-type{
    /* white */
transition: 0.3s ease all;
background: #FFFFFF;
/* greyLight */

border: 1px solid #D2D8E0;
border-radius: 8px;
}
</style>